/**
 * Global Styles - App
 *
 * Any global styles overrides or additional styles can be included in this file
 */
import React from 'react';
import { createGlobalStyle, Normalize, thd } from '@smooth-ui/core-sc';
import loriGlobalStyles from '_platform/src/theme/globalStyles';

export const GlobalStylesApp = createGlobalStyle`
  ${loriGlobalStyles}

  /*@font-face {
    font-family: 'Brandon Grotesque';
    font-weight: bold;
    src: url(../fonts/Brandon_Grotesque_bold.otf) format('opentype');
  }*/

  @font-face {
    font-family: 'Univers LT Pro';
    src: url(../fonts/UniversLTPro-45Light.eot);
    src: url(../fonts/UniversLTPro-45Light.woff2) format('woff2'),
        url(../fonts/UniversLTPro-45Light.woff) format('woff'),
        url(../fonts/UniversLTPro-45Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
      font-family: 'Brandon Grotesque';
      src: url(../fonts/BrandonGrotesque-Bold.eot);
      src: url(../fonts/BrandonGrotesque-Bold.woff2) format('woff2'),
          url(../fonts/BrandonGrotesque-Bold.woff) format('woff'),
          url(../fonts/BrandonGrotesque-Bold.ttf) format('truetype');
      font-weight: bold;
      font-style: normal;
  }

  @font-face {
      font-family: 'Univers LT Pro';
      src: url(../fonts/UniversLTPro-55Roman.eot);
      src: url(../fonts/UniversLTPro-55Roman.woff2) format('woff2'),
          url(../fonts/UniversLTPro-55Roman.woff) format('woff'),
          url(../fonts/UniversLTPro-55Roman.ttf) format('truetype');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'Brandon Grotesque';
      src: url(../fonts/BrandonGrotesque-Light.eot);
      src: url(../fonts/BrandonGrotesque-Light.woff2) format('woff2'),
          url(../fonts/BrandonGrotesque-Light.woff) format('woff'),
          url(../fonts/BrandonGrotesque-Light.ttf) format('truetype');
      font-weight: 300;
      font-style: normal;
  }


  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

  body {
    font-family: 'Univers LT Pro', 'Source Sans Pro', Tahoma, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Brandon Grotesque';
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;
  }

  /* Custom CSS here */
  h1 {
    font-size: 40px;
    margin-bottom: .25em;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 12px;
  }

  a {
    color: #01916D;

    &:visited {
      color: #01916D;
    }

    &:hover {
      text-decoration: none;
    }
  }

  b, strong {
  font-weight: bold;
}

  /* Don't wrap emails, phones or other non-wrapping strings */
  .nowrap,
  .date,
  .email,
  .phone {
    white-space: nowrap;
  }

  /* Color text */
  .text--red {
    color: ${thd('danger', '#dc3545')};
  }

  .text--white {
    color: #fff;
  }

  .text--primary {
    color: ${thd('primary', '#867455')};
  }

  /* Align text */
  .text-center,
  .text-centre,
  .text--center,
  .text--centre {
    text-align: center;
  }
  .text-right,
  .text--right {
    text-align: right;
  }
  .text-left,
  .text--left {
    text-align: left;
  }

  .text-small,
  .text--small {
    font-size: 80%;
  }

  .text-large,
  .text--large {
    font-size: 120%;
  }

  .text-larger,
  .text--larger {
    font-size: 200%;
  }

  .text-normal,
  .text--normal {
    font-weight: normal;
  }
  .text-uppercase,
  .text--uppercase {
    text-transform: uppercase;
  }

  .text--tight {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  /* Handy spacing classes */
  :root {
    --spacer: 2rem;
  }

  .spacer {
    padding-bottom: 40px;
    padding-top: 40px;
    padding-bottom: var(--spacer);
    padding-top: var(--spacer);
  }
  .spacer--small {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-bottom: calc(var(--spacer) / 2);
    padding-top: calc(var(--spacer) / 2);
  }
  .spacer--large {
    padding-bottom: 80px;
    padding-top: 80px;
    padding-bottom: calc(var(--spacer) * 2);
    padding-top: calc(var(--spacer) * 2);
  }
  .spacer--around {
    padding: 40px;
    padding: var(--spacer);
  }
  .spacer--around--small {
    padding: 20px;
    padding: calc(var(--spacer) / 2);
  }
  .spacer--around--large {
    padding: 80px;
    padding: calc(var(--spacer) * 2);
  }
  .spacer--top {
    padding-top: 40px;
    padding-top: var(--spacer);
  }
  .spacer--bottom {
    padding-bottom: 40px;
    padding-bottom: var(--spacer);
  }
  .spacer--top--small,
  .spacer--small--top {
    padding-top: 20px;
    padding-top: calc(var(--spacer) / 2);
  }
  .spacer--bottom--small,
  .spacer--small--bottom {
    padding-bottom: 20px;
    padding-bottom: calc(var(--spacer) / 2);
  }
  .spacer--top--large,
  .spacer--large--top {
    padding-top: 80px;
    padding-top: calc(var(--spacer) * 2);
  }
  .spacer--bottom--large,
  .spacer--large--bottom {
    padding-bottom: 80px;
    padding-bottom: calc(var(--spacer) * 2);
  }

  .accordion__panel__spacing {
    padding-left: 20px;
    padding-right: 20px;
  }

  .Toastify .Toastify__toast--success {
    background: #07bc0c;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

const StylesApp = () => (
  <React.Fragment>
    <Normalize />
    <GlobalStylesApp />
  </React.Fragment>
);

export default StylesApp;
